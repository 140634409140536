import { SerializedStyles } from '@emotion/core';
import { createContainer, createHook, createStore } from 'react-sweet-state';

type State = {
    styles?: SerializedStyles;
};

type Props = {
    styles: SerializedStyles;
};

const initialState: State = {};

const actions = {};

const Store = createStore<State, typeof actions>({
    initialState,
    actions,
    name: 'default-fallback-styling',
});

export const DefaultFallbackStylingContainer = createContainer<State, typeof actions, Props>(
    Store,
    {
        onInit: () => ({ setState }, { styles }) => {
            setState({ styles });
        },
    },
);

export const useDefaultFallbackStyling = createHook(Store);
