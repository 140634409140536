export const pluginKey = process.env.PLUGIN_KEY as string;
export const environment = process.env.ENVIRONMENT as
    | 'local'
    | 'development'
    | 'beta'
    | 'production';
export const release = process.env.RELEASE as string;
export const gaTrackingId = process.env.GA_TRACKING_ID as string;
export const checkLicensing = process.env.CHECK_LICENSING !== 'false';
export const isForge = process.env.FORGE === 'true';
