import React from 'react';

import { css } from '@emotion/core';
import { DefaultFallbackStylingContainer } from '@gelatofm/img-utils';
import { environment, release } from '@jxl/environment';
import { init as initSentry } from '@sentry/node';
import { Integrations } from '@sentry/tracing';
import { AppProps } from 'next/app';
import { defaults } from 'react-sweet-state';

import '@atlaskit/css-reset';
import { N50 } from '@atlaskit/theme/colors';

defaults.batchUpdates = false;

const noBreadcrumbCategories = [/^ui\./, /^console$/, /^fetch$/, /^navigation$/];

const fallbackCss = css`
    background: ${N50};
    border-radius: 3px;
`;

initSentry({
    dsn: 'https://39f20d5767f94b3daa711b43f0296fb2@o453258.ingest.sentry.io/5442406',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment,
    release,
    beforeBreadcrumb: (breadcrumb) => {
        const { category } = breadcrumb;

        if (category && !!noBreadcrumbCategories.find((regex) => regex.test(category))) {
            return null;
        }

        return breadcrumb;
    },
    beforeSend: (event) => ({
        ...event,
        request: undefined,
    }),
});

export default function App({ Component, pageProps, err }: AppProps & { err: Error }) {
    // Workaround for https://github.com/vercel/next.js/issues/8592
    return (
        <DefaultFallbackStylingContainer styles={fallbackCss}>
            <Component {...pageProps} err={err} />
        </DefaultFallbackStylingContainer>
    );
}
